<template>

	<!-- 加载中画面 -->
	<div class="content">
		<div id="loading-screen" v-if="!finish_load">
			<div class="spinner"></div>
			<p class="loading-text">努力加载中...</p>
		</div>

		<div id="loading-screen" v-if="fail && finish_load">
			<p class="loading-text">加载失败...</p>
			<p class="loading-text2" @click="retry">[ 点击重新加载 ] </p>
		</div>

		<div id="loading-screen" v-if="!fail && finish_load">
			<div class="spinner"></div>
			<p class="loading-text2"><a :href="url">[ 手动点击进行跳转 ] </a></p>
		</div>
	</div>
</template>
<script>
import axios from 'axios';
import CryptoJS from 'crypto-js'
export default {
	data() {
		return {
			url: '',
			finish_load: false,
			subdomain: '',
			protocol: 'https://',
			fail: true
		};
	},
	created() {
		this.load_web();
	},
	mounted() {

	},

	methods: {
		reinit() {
			this.url = '';
			this.fail = false;
			this.finish_load = false;
		},
		retry() {
			this.reinit();
			this.load_web();
		},
		load_web() {
			let web_url = (this.$cfg.VUE_APP_URL);
			let urls = this.aesDencrypt(web_url);
			/*
			let web_url2 = (this.$cfg.VUE_APP_URL_JSON);
			let web_url3 = (this.$cfg.VUE_APP_URL_JSON2);
			let urls2 = (JSON.parse(web_url2));
			let urls3 = (JSON.parse(web_url3));
			let enc = this.aesEncrypt(web_url2);
			let enc2 = this.aesEncrypt(web_url3);
			
			console.log(enc);
			console.log(enc2);
			console.log(this.aesDencrypt(enc));
			console.log(this.aesDencrypt(enc2));
			*/

			let run = 0;
			this.subdomain = this.generateRandomString(18);
			let that = this;
			const accessible_results = [];
			const url_len = urls.length;
			this.checkLinks(urls).then(results => {
				results.forEach(result => {
					if (result.status === 'accessible') {
						accessible_results.push(result.url);
					}
					run++;

					if (run >= url_len) {
						that.finish_load = true;
						if (accessible_results.length > 0) {
							// 从可访问链接中随机选一个
							that.url = this.protocol + this.subdomain + "." + accessible_results[Math.floor(Math.random() * accessible_results.length)];
							that.fail = false;
							window.location.href = that.url;

						} else {
							that.fail = true;
						}

					}
				});
			});
		},
		checkLinks(urls) {
			const results = [];

			// 使用 Promise.all 并发检查所有链接
			const promises = urls.map(url => this.checkLink(url));
			return Promise.all(promises);

		},
		async checkLink(url) {


			var u = (url == 'gp3-admin.local' || url == 'gp4-admin.local' ? 'http:\/\/' + url + "/api/update/status" : this.protocol + this.subdomain + "." + url + "/prod-api" + "/api/update/status");

			// 创建一个Promise用于超时处理
			const timeout = new Promise((_, reject) =>
				setTimeout(() => reject('Timeout exceeded'), 2000) // 超过5秒后reject
			);

			// 使用 Promise.race 来并行处理 fetch 和超时
			return Promise.race([
				fetch(u).then(response => {
					if (response.ok && response.status == 202) {
						return { url, status: 'accessible' };
					} else {
						return { url, status: `error: ${response.status}` };
					}
				}),
				timeout // 超时Promise
			]).catch(error => {
				// 捕获超时或其他错误
				return { url, status: error };
			});

		},
		generateRandomString(length = 16) {
			const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
			let result = '';
			for (let i = 0; i < length; i++) {
				const randomIndex = Math.floor(Math.random() * chars.length);
				result += chars[randomIndex];
			}
			return result;
		},
		aesEncrypt(txt) {
			const cipher = CryptoJS.AES.encrypt(txt, CryptoJS.enc.Utf8.parse(this.$cfg.VUE_APP_KEY), {
				iv: CryptoJS.enc.Utf8.parse(this.$cfg.VUE_APP_IV),
				mode: CryptoJS.mode.CBC
			});
			return cipher.toString();
		},

		aesDencrypt(data) {
			var key = this.$cfg.VUE_APP_KEY;
			var iv = this.$cfg.VUE_APP_IV;

			// 将密文进行URL解码
			data = decodeURIComponent(data);

			var ciphertext = CryptoJS.enc.Base64.parse(data);
			//ciphertext = data;

			// 使用密钥和IV解密数据
			var decrypted = CryptoJS.AES.decrypt({
				ciphertext: ciphertext
			},
				CryptoJS.enc.Utf8.parse(key), {
				iv: CryptoJS.enc.Utf8.parse(iv)
			}
			);

			var response = decrypted.toString(CryptoJS.enc.Utf8);
			return (JSON.parse(response));
		}

	},
};
</script>

<style lang="scss">
/* 设置页面背景和居中样式 */
body {
	padding: 0;
	margin: 0;
	font-size: 14px;
}

.content {
	margin: 0;
	padding: 0;
	font-family: Arial, sans-serif;
	background-color: #222;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100vw;
}

/* 加载中画面 */
#loading-screen {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

/* 圆形加载动画 */
.spinner {
	width: 50px;
	height: 50px;
	border: 5px solid #ccc;
	border-top: 5px solid #007bff;
	/* 颜色设置 */

	border-radius: 50%;
	animation: spin 1s linear infinite;
}

/* 加载文本样式 */
.loading-text {
	font-size: 16px;
	color: #f1f1f1;
	font-weight: bold;
}

a,
.loading-text2 {
	color: #f1f1f1;
	cursor: pointer;
	text-decoration: none;
}

p {
	margin: 10px 0;
}

/* 动画关键帧 */
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
</style>